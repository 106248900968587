<template>
  <div>
    <!-- <el-autocomplete
      style="width: 100%"
      v-model="curValue"
      :fetch-suggestions="CustomerSearchAsync"
      value-key="cCusName"
      placeholder="请输入签收人名称进行查询"
      @select="handleCustomerSelect"
      :trigger-on-focus="false"
    ></el-autocomplete> -->
    <el-select
      v-model="curValue"
      placeholder="请选择签收人"
      style="width: 100%"
      :disabled="disabled"
      @change="handleCustomerSelect"
    >
      <el-option
        v-for="item in options"
        :key="item.Id"
        :label="`${item.cCusName}(${item.cName})`"
        :value="item.Id"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    value: { require: false, default: '', type: String },
    disabled: { require: false, default: false, type: Boolean },
    cCusCode: { require: false, default: '', type: String },
    CompanyId: { require: false, default: '', type: String },
  },
  data() {
    return {
      curValue: '',
      options: [],
    }
  },
  watch: {
    value: {
      handler(n, o) {
        this.curValue = n
        this.$emit('input', this.curValue)
      },
      deep: true,
      immediate: true,
    },
    cCusCode(val) {
      if (val) {
        this.getCustomerList(val)
      } else {
        this.options = []
      }
    },
  },
  methods: {
    getCustomerList(code) {
      this.$http
        .post('/BO/CustomerLinkman/GetDataList', {
          Search: {
            Keys: code,
            SupplieId: this.CompanyId,
          },
        })
        .then((res) => {
          if (res.Success) {
            this.options = res.Data
          } else {
            this.$message.error(res.Msg)
          }
        })
    },
    CustomerSearchAsync(queryString, cb) {
      clearTimeout(this.timeout)
      if (!queryString) {
        return
      }
      this.timeout = setTimeout(() => {
        this.$http
          .post('/BO/CustomerLinkman/GetDataList', {
            PageIndex: 1,
            PageRows: 9999,
            SortField: 'Id',
            SortType: 'desc',
            Search: {
              condition: 'cName',
              keyword: queryString,
            },
          })
          .then((resJson) => {
            if (resJson.Success) {
              cb(resJson.Data)
            }
          })
      }, 1000)
    },
    handleCustomerSelect(item) {
      var row = this.options.find((val) => {
        return val.Id === item
      })
      this.$emit('select', row)
      this.$emit('input', this.curValue)
    },
  },
}
</script>

<style></style>
