<template>
  <el-dialog
    title="选择货品"
    width="60%"
    :visible.sync="dialogTableVisible"
    append-to-body
  >
    <div class="searchNav">
      <div class="searchParams">
        <el-input
          class="input"
          v-model="queryParam.Keyword"
          placeholder="请输入编号或名称"
          clearable
          @keydown.enter.native="getDataList"
        ></el-input>
        <el-button class="button" @click="getDataList">查询</el-button>
      </div>
    </div>
    <div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        style="width: 100%"
        :max-height="450"
        v-loading="loading"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="50" align="center">
        </el-table-column>
        <!-- <el-table-column prop="CompanyName" label="所属公司" width="200">
        </el-table-column> -->
        <el-table-column prop="cInvCode" label="编号"> </el-table-column>
        <el-table-column prop="cInvName" label="品名"> </el-table-column>
        <el-table-column prop="cInvStd" label="包装"> </el-table-column>
        <el-table-column prop="iInvRCost" label="销售价格" align="right">
          <template slot-scope="scope">{{
            toThousands(scope.row.iInvRCost)
          }}</template>
        </el-table-column>
        <!-- <el-table-column label="厂家" width="200">
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.changjia"
              placeholder="请选择厂家"
              style="width: 100%"
            >
              <el-option label="厂家一" value="厂家一"></el-option>
            </el-select>
          </template>
        </el-table-column> -->
      </el-table>
      <el-pagination
        layout="total,prev, pager, next,sizes"
        :total="pagination.total"
        :current-page="pagination.current"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.pageSizeOptions"
        style="text-align: right; margin-top: 8px; padding: 0"
        @current-change="currentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { toThousands } from "@/utils/Tools";
export default {
  props: {},
  mounted() {},
  created() {},
  data() {
    return {
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      },
      sorter: {
        field: "Id",
        order: "desc",
      },
      tableData: [],
      queryParam: { condition: "cInvName", SupplieId: "全部" },
      loading: false,
      toThousands,
      dialogTableVisible: false,
      selectRows: [],
    };
  },
  methods: {
    handleSelectionChange(val) {
      this.selectRows = val;
    },
    // 取消
    cancel() {
      this.dialogTableVisible = false;
    },
    // 确认
    confirm() {
      if (this.selectRows.length <= 0) {
        this.$message.error("请选择货品");
      }
      this.$emit("select", [...this.selectRows]);
      this.dialogTableVisible = false;
    },
    // 开启页面
    openForm(customId, CompanyId) {
      if (this.$refs.multipleTable) {
        this.$refs.multipleTable.clearSelection();
      }
      this.queryParam.customId = customId;
      this.queryParam.SupplieId = CompanyId;
      this.getDataList();
      this.dialogTableVisible = true;
    },
    currentChange(page) {
      this.pagination.current = page;
      this.getDataList();
    },
    handleSizeChange(lint) {
      this.pagination.pageSize = lint;
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      this.$http
        .post("/BO/BillList/GetInventoryList", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          SortField: this.sorter.field || "Id",
          SortType: this.sorter.order,
          Search: this.queryParam,
        })
        .then((resJson) => {
          this.loading = false;
          if (resJson.Success) {
            this.tableData = resJson.Data;
            if (this.tableData) {
              const pagination = { ...this.pagination };
              pagination.total = resJson.Total;
              this.pagination = pagination;
            }
          }
        });
    },
  },
  computed: {},
  watch: {},
};
</script>

<style lang="less" scoped></style>
