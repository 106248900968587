<template>
  <div>
    <el-select
      @change="handleCompanySelect"
      v-model="curValue"
      placeholder="请选择承运方"
      style="width: 100%"
    >
      <el-option
        v-for="item in List"
        :key="item.Id"
        :label="item.CarrierName"
        :value="item.Id"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
import op from "@/utils/OperatorCache";
export default {
  props: {
    value: { require: false, default: "", type: String },
    disabled: { require: false, default: false, type: Boolean },
    CompanyId: { require: true, type: String },
  },
  data() {
    return {
      curValue: "",
      List: [],
    };
  },
  watch: {
    value: {
      handler(n, o) {
        this.curValue = n;
        this.$emit("input", this.curValue);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    getDataList() {
      this.$http
        .post("/Base/Base_Carrier/GetDataList", {
          // PageIndex: 1,
          // PageRows: 9999,
          // SortField: 'Id',
          // SortType: 'desc',
          // Search: {
          //   condition: 'CreatorId',
          //   keyword: JSON.parse(sessionStorage.getItem('userInfo')).Id,
          // },
          Search: {
            SupplieId: this.CompanyId,
          },
        })
        .then((resJson) => {
          if (resJson.Success) {
            this.List = resJson.Data;
          }
        });
    },
    handleCompanySelect(item) {
      console.log(this.curValue);
      let idx = this.List.findIndex((im) => im.Id == item);
      this.$emit("select", this.List[idx]);

      this.$emit("input", this.curValue);
    },
  },
};
</script>

<style></style>
